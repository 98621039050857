<template>
  <div>
    <div v-if="$vuetify.breakpoint.mdAndUp">
      <v-btn
        color="#1E853A"
        width="115"
        :disabled="!commentCO"
        @click="commentTicket"
        class="text-none rounded-lg font-weight-regular mt-2 white--text mr-4"
        >{{ $t("comment") }}</v-btn
      >
      <v-btn
        color="#1E853A"
        width="115"
        :disabled="!addMembersCO"
        @click="addMembersTicket"
        class="text-none rounded-lg font-weight-regular mt-2 white--text mr-4"
        >{{ $t("addMembers") }}</v-btn
      >
      <v-btn
        color="#1E853A"
        width="130"
        :disabled="!relocateRefreshCO"
        @click="relocateRefreshTicket"
        class="text-none rounded-lg font-weight-regular mt-2 white--text mr-4"
        >{{ $t("relocateRefresh") }}</v-btn
      >
      <v-btn
        color="#1E853A"
        width="115"
        :disabled="!secondNoticeCO"
        @click="secondNoticeTicket"
        class="text-none rounded-lg font-weight-regular mt-2 white--text mr-4"
        >{{ $t("secondNotice") }}</v-btn
      >
      <v-btn
        color="#1E853A"
        width="110"
        :disabled="!updateCO"
        @click="updateTicketDetails('UpdateTicket')"
        class="text-none rounded-lg font-weight-regular mt-2 white--text mr-4"
        >{{ $t("update") }}</v-btn
      >
      <v-btn
        color="#1E853A"
        width="130"
        :disabled="!utilityNotFoundCO"
        @click="utilityNotFoundTicket"
        class="text-none rounded-lg font-weight-regular mt-2 white--text mr-4"
        >{{ $t("utilityNotFound") }}</v-btn
      >
      <v-btn
        color="#1E853A"
        width="115"
        class="text-none rounded-lg font-weight-regular mt-2 white--text"
        :disabled="!cancelCO"
        @click="cancelTicket"
        >{{ $t("cancel") }}</v-btn
      >
    </div>
    <div v-else>
      <v-btn
        color="#1E853A"
        width="130"
        :disabled="!commentCO"
        @click="commentTicket"
        class="text-none rounded-lg font-weight-regular mt-2 white--text ml-2 mr-4"
        >{{ $t("comment") }}</v-btn
      >
      <v-btn
        color="#1E853A"
        width="135"
        :disabled="!addMembersCO"
        @click="addMembersTicket"
        class="text-none rounded-lg font-weight-regular mt-2 white--text ml-2 mr-4"
        >{{ $t("addMembers") }}</v-btn
      >
      <v-btn
        color="#1E853A"
        width="130"
        :disabled="!relocateRefreshCO"
        @click="relocateRefreshTicket"
        class="text-none rounded-lg font-weight-regular mt-2 white--text ml-2 mr-4"
        >{{ $t("relocateRefresh") }}</v-btn
      >
      <v-btn
        color="#1E853A"
        width="135"
        :disabled="!secondNoticeCO"
        @click="secondNoticeTicket"
        class="text-none rounded-lg font-weight-regular mt-2 white--text ml-2 mr-4"
        >{{ $t("secondNotice") }}</v-btn
      >
      <v-btn
        color="#1E853A"
        width="130"
        :disabled="!updateCO"
        @click="updateTicketDetails('UpdateTicket')"
        class="text-none rounded-lg font-weight-regular mt-2 white--text ml-2 mr-4"
        >{{ $t("update") }}</v-btn
      >

      <v-btn
        color="#1E853A"
        width="135"
        :disabled="!utilityNotFoundCO"
        @click="utilityNotFoundTicket"
        class="text-none rounded-lg font-weight-regular mt-2 white--text ml-2 mr-4"
      >
        {{ $t("utilityNotFound") }}</v-btn
      >
      <v-btn
        color="#1E853A"
        width="130"
        class="text-none rounded-lg font-weight-regular mt-2 ml-2 white--text"
        :disabled="!cancelCO"
        @click="cancelTicket"
        >{{ $t("cancel") }}</v-btn
      >
    </div>
    <AddMembers
      v-if="addMembersTicketDialog"
      :ticket-data="ticketDetails"
      :dialog="addMembersTicketDialog"
      @retransmitTicketSubmit="retransmitTicketSubmit"
      @closeRetransmitDialog="closeRepeatTicketDialog"
      @addMembersTicketSuccess="addMembersTicketSuccess"
      @errorSubmitTicket="errorSubmitTicket"
    />
    <RelocateRefreshDialog
      v-if="relocateRefreshDialog"
      :ticket-data="ticketDetails"
      :dialog="relocateRefreshDialog"
      @relocateRefreshTicketSubmit="relocateRefreshTicketSubmit"
      @closeRelocateRefreshDialog="closeRelocateRefreshDialog"
      @relocateRefreshTicketSuccess="relocateRefreshTicketSuccess"
      @errorSubmitTicket="errorSubmitTicket"
    />
    <CancelTicketDialogCO
      v-if="cancelTicketDialog"
      :ticket-data="ticketDetails"
      :dialog="cancelTicketDialog"
      @cancelTicketSubmit="cancelTicketSubmit"
      @closeDialog="closeCancelTicketDialog"
      @cancelTicketSuccess="cancelTicketSuccess"
      @errorSubmitTicket="errorSubmitTicket"
    />
    <CommentTicketDialogCO
      v-if="commentTicketDialog"
      :ticket-data="ticketDetails"
      :dialog="commentTicketDialog"
      @commentTicketSubmit="commentTicketSubmit"
      @closeDialog="closeCommentTicketDialog"
      @commentTicketSuccess="commentTicketSuccess"
      @errorSubmitTicket="errorSubmitTicket"
    />
    <SecondNoticeDialogCO
      v-if="secondNoticeTicketDialog"
      :ticket-data="ticketDetails"
      :dialog="secondNoticeTicketDialog"
      @secondNoticeTicketSubmit="secondNoticeTicketSubmit"
      @closeSecondNoticeTicketDialog="closeSecondNoticeTicketDialog"
      @secondNoticeTicketSuccess="secondNoticeTicketSuccess"
      @errorSubmitTicket="errorSubmitTicket"
    />
    <UtilityNotFoundDialogCO
      v-if="utilityNotFoundTicketDialog"
      :ticket-data="ticketDetails"
      :dialog="utilityNotFoundTicketDialog"
      @utilityNotFoundTicketSubmit="utilityNotFoundTicketSubmit"
      @closeUtilityNotFoundTicketDialog="closeUtilityNotFoundTicketDialog"
      @utilityNotFoundTicketSuccess="utilityNotFoundTicketSuccess"
      @errorSubmitTicket="errorSubmitTicket"
    />
    <ErrorMessage
      v-if="isError"
      :error-code="errorCode"
      :error-message="errorMessage"
      @ticketScreen="isError = false"
    />
  </div>
</template>

<script>
import moment from "moment";
import EditTicket from "@/mixins/EditTicket";
import TicketSecondaryFunctions from "@/mixins/TicketSecondaryFunctions";
export default {
  mixins: [EditTicket, TicketSecondaryFunctions],
  name: "TickDetails",
  components: {
    ErrorMessage: () => import("../../../../molecules/ErrorMessage.vue"),
    AddMembers: () => import("./AddMembers.vue"),
    RelocateRefreshDialog: () => import("./RelocateRefreshDialog.vue"),
    CancelTicketDialogCO: () => import("./CancelTicketDialogCO.vue"),
    SecondNoticeDialogCO: () => import("./SecondNoticeDialogCO.vue"),
    UtilityNotFoundDialogCO: () => import("./UtilityNotFoundDialogCO.vue"),
    CommentTicketDialogCO: () => import("./CommentTicketDialog.vue"),
  },
  props: {
    ticketDetails: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    cancelCO: false,
    commentCO: false,
    addMembersCO: false,
    relocateRefreshCO: false,
    secondNoticeCO: false,
    updateCO: false,
    utilityNotFoundCO: false,
    currentTab: "Tasks",
    dialog: true,
    cancelTicketDialog: false,
    commentTicketDialog: false,
    secondNoticeTicketDialog: false,
    utilityNotFoundTicketDialog: false,
    addMembersTicketDialog: false,
    relocateRefreshDialog: false,
    hourNoticeTicketDialog: false,
    isViewProjectDetails: false,
    addProjectDialog: false,
    isUploadFiles: false,
    ticketData: {},
    viewExpantionPanel: false,
    errorCode: "",
    errorMessage: "",
    isError: false,
  }),
  computed: {},
  async created() {
    this.moment = moment;
  },
  async mounted() {
    this.ticketData = Object.assign({}, this.ticketDetails);
    await this.checkSecondaryFunctionsEligibility();
  },
  methods: {
    errorSubmitTicket(data) {
      this.errorCode = data.errorCode.toString();
      this.errorMessage = data.error;
      this.isError = true;
    },
    relocateRefreshTicket() {
      this.relocateRefreshDialog = true;
    },
    closeRelocateRefreshDialog() {
      this.relocateRefreshDialog = false;
    },
    relocateRefreshTicketSubmit() {
      this.relocateRefreshDialog = false;
    },
    async relocateRefreshTicketSuccess(ticketNumber, ticketRevision) {
      this.relocateRefreshDialog = false;
      this.$emit("relocateRefreshTicketSuccess", ticketNumber, ticketRevision);
    },

    addMembersTicket() {
      this.addMembersTicketDialog = true;
    },
    hourNoticeTicket() {
      this.hourNoticeTicketDialog = true;
    },
    closeHourNoticeTicketDialog() {
      this.hourNoticeTicketDialog = false;
    },
    hourNoticeTicketSubmit() {
      this.hourNoticeTicketDialog = false;
    },
    closeRepeatTicketDialog() {
      this.addMembersTicketDialog = false;
    },
    retransmitTicketSubmit() {
      this.addMembersTicketDialog = false;
    },
    async addMembersTicketSuccess(
      retransmitTicketNumber,
      retransmitTicketRevision
    ) {
      this.addMembersTicketDialog = false;
      this.$emit(
        "addMembersTicketSuccess",
        retransmitTicketNumber,
        retransmitTicketRevision
      );
    },
    async updateTicketDetails(editType) {
      await this.updateTicket(this.ticketDetails, editType);
      this.$router.push("/createTicket").catch();
    },
    cancelTicket() {
      this.cancelTicketDialog = true;
    },
    closeCancelTicketDialog() {
      this.cancelTicketDialog = false;
    },
    cancelTicketSubmit() {
      this.cancelTicketDialog = false;
    },
    async cancelTicketSuccess(cancelTicketNumber, cancelTicketRevision) {
      this.cancelTicketDialog = false;
      this.$emit(
        "cancelTicketSuccess",
        cancelTicketNumber,
        cancelTicketRevision
      );
    },
    commentTicket() {
      this.commentTicketDialog = true;
    },
    closeCommentTicketDialog() {
      this.commentTicketDialog = false;
    },
    commentTicketSubmit() {
      this.commentTicketDialog = false;
    },
    async commentTicketSuccess(commentTicketNumber, commentTicketRevision) {
      this.commentTicketDialog = false;
      this.$emit(
        "commentTicketSuccess",
        commentTicketNumber,
        commentTicketRevision
      );
    },
    secondNoticeTicket() {
      this.secondNoticeTicketDialog = true;
    },
    closeSecondNoticeTicketDialog() {
      this.secondNoticeTicketDialog = false;
    },
    secondNoticeTicketSubmit() {
      this.secondNoticeTicketDialog = false;
    },
    async secondNoticeTicketSuccess(ticketNumber, ticketRevision) {
      this.secondNoticeTicketDialog = false;
      this.$emit("secondNoticeTicketSuccess", ticketNumber, ticketRevision);
    },
    utilityNotFoundTicket() {
      this.utilityNotFoundTicketDialog = true;
    },
    closeUtilityNotFoundTicketDialog() {
      this.utilityNotFoundTicketDialog = false;
    },
    utilityNotFoundTicketSubmit() {
      this.utilityNotFoundTicketDialog = false;
    },
    async utilityNotFoundTicketSuccess(ticketNumber, ticketRevision) {
      this.utilityNotFoundTicketDialog = false;
      this.$emit("utilityNotFoundTicketSuccess", ticketNumber, ticketRevision);
    },

    getNextWorkDay(date, days) {
      var result = new Date(date);
      result.setDate(result.getDate() + days);
      return result;
    },
  },
};
</script>

<style lang="scss" scoped></style>
